import { getBackendHost, backendRoutes } from '@reshima/client-server';
import { CategoriesBatchPayload, SearchResults } from './models';

const { category, categoryAutoComplete } = backendRoutes;

export async function getCategory({
  search,
  awaitLog,
  token,
}: {
  search: string;
  awaitLog?: boolean;
  token: string;
}): Promise<SearchResults | undefined> {
  const { path, query } = category;

  const baseUrl = `${getBackendHost()}${path}`;

  const params = new URLSearchParams({
    [query.search]: search,
  });

  if (awaitLog) {
    params.set(query.awaitLog, 'true');
  }

  const url = `${baseUrl}?${params}`;

  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return response.json();
}

export async function suggestCategory({
  search,
  token,
}: {
  search: string;
  token: string;
}): Promise<SearchResults | undefined> {
  const { path, query } = categoryAutoComplete;

  const baseUrl = `${getBackendHost()}${path}`;

  const params = new URLSearchParams({
    [query.search]: search,
  });

  const url = `${baseUrl}?${params}`;

  const response = await fetch(url, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return response.json();
}

export async function batchGetCategories({
  token,
  searches,
}: {
  token: string;
  searches: string[];
}): Promise<Record<string, SearchResults>> {
  const { path } = backendRoutes.categoriesBatch;

  const baseUrl = `${getBackendHost()}${path}`;

  const payload: CategoriesBatchPayload = { searches };

  const response = await fetch(baseUrl, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }

  return response.json();
}
