import { FirebaseApp } from 'firebase/app';
import { Functions, getFunctions } from 'firebase/functions';
import { ActionModifier, trackEvent, trackException } from '@reshima/telemetry';

const name = 'FirebaseCallable';

let callable: Functions;

export function initCallable(app: FirebaseApp): void {
  const action = 'Initialize';

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
  });

  try {
    callable = getFunctions(app, 'europe-west3'); // TODO: Shared constant

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      start,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      start,
    });
  }
}

export function getCallable() {
  return callable;
}
