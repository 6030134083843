import type { User } from 'firebase/auth';
import type { FieldValue, Timestamp } from 'firebase/firestore';
import { Units, ProviderId } from '@reshima/shared';
import type { Category, CategoryId } from '@reshima/category';

export type AppUser = {
  firebaseUser: User;
  isAnonymous: boolean;
  isSignedIn: boolean;
  providers: Record<ProviderId, boolean>;
  userImage: string | null;
};

export type Contact = {
  name: string;
};

export type Settings = {
  addingItem: AddingItem;
};

export type AddingItem = {
  autoComplete: AddingItemAutoComplete;
};

export type AddingItemAutoComplete = {
  parseItemCount: boolean;
  parseItemUnit: boolean;
  parseItemCategory: boolean;
};

export type FirebaseSettings = {
  addingItem: FirebaseAddingItem;
};

export type FirebaseAddingItem = {
  autoComplete: FirebaseAddingItemAutoComplete;
};

export type FirebaseAddingItemAutoComplete = Partial<AddingItemAutoComplete>;

export type FirebaseUserData = {
  name?: string;
  isAdmin?: boolean;
  contacts?: Record<string, Contact>;
  updatedAt?: Timestamp;
  settings?: FirebaseSettings;
};

export type NewFirebaseUserData = Omit<FirebaseUserData, 'updatedAt'> & {
  updatedAt: FieldValue;
};

export type UserData = Omit<FirebaseUserData, 'updatedAt'> & {
  id: string;
  name: string;
  contacts: Record<string, Contact>;
  updatedAt: Date;
  settings: Settings;
};

export type UpdateAbleUserData = Partial<NewFirebaseUserData> & { id: string };

export enum ListSortBy {
  fixedCategories = 'fixedCategories',
  manual = 'manual',
  lastUpdatedCategory = 'lastUpdatedCategory',
  lastCreatedItem = 'lastCreatedItem',
}

export type FirebaseList = {
  clientCreatedAt?: Timestamp;
  createdAt?: Timestamp;
  updatedAt?: Timestamp;
  admins?: string[];
  sharedWith?: string[];
  itemsOrder?: string[];
  categoriesOrder?: CategoryId[];
  fixedCategoriesOrder?: CategoryId[];
  shareLink?: string;
  name?: string;
  sortBy?: ListSortBy;
};

export type NewFirebaseList = Omit<FirebaseList, 'createdAt' | 'updatedAt'> & {
  createdAt: FieldValue;
  updatedAt: FieldValue;
  shareLink: string;
  admins: string[];
};

export type List = Omit<
  FirebaseList,
  'clientCreatedAt' | 'createdAt' | 'updatedAt'
> & {
  id: string;
  admins: string[];
  sharedWith: string[];
  clientCreatedAt: Date;
  createdAt: Date;
  updatedAt: Date;
  sortBy: ListSortBy;
  categoriesOrder: CategoryId[];
};

export type UpdateAbleList = Omit<
  Partial<NewFirebaseList>,
  'fixedCategoriesOrder'
> & {
  id: string;
  fixedCategoriesOrder?: FieldValue | CategoryId[];
};

export type FirebaseItem = {
  clientCreatedAt?: Timestamp;
  clientUpdatedAt?: Timestamp;
  checkedUpdatedAt?: Timestamp;
  createdAt?: Timestamp;
  updatedAt?: Timestamp;
  name?: string;
  checked?: boolean;
  categoryId?: CategoryId;
  count?: number;
  unit?: Units;
};

export type Item = Omit<
  FirebaseItem,
  | 'clientCreatedAt'
  | 'clientUpdatedAt'
  | 'checkedUpdatedAt'
  | 'createdAt'
  | 'updatedAt'
> & {
  id: string;
  name: string;
  clientCreatedAt: Date;
  clientUpdatedAt: Date;
  checkedUpdatedAt: Date;
  createdAt: Date;
  updatedAt: Date;
  categoryId: CategoryId;
  count: number;
  unit: Units;
};

export type UpdateAbleItem = Omit<
  Partial<Item>,
  'checked' | 'updatedAt' | 'checkedUpdatedAt' | 'clientUpdatedAt'
> & {
  id: string;
  checked?: FieldValue | boolean;
  checkedUpdatedAt?: FieldValue | Date;
  clientUpdatedAt?: FieldValue | Date;
  updatedAt?: FieldValue | Date;
};

export type CategoryItems = {
  category: Category;
  categoryItems: Item[];
};

export type ItemCategoryAutoCompleteCollectionItem = {
  count: number;
};

export type ItemCategoryManualUpdateCollectionItem = {
  count: number;
};

export type FirebaseParticipant = {
  name?: string;
  createdAt?: Timestamp;
};

export type Participant = Omit<FirebaseParticipant, 'createdAt'> & {
  id: string;
  name: string;
  createdAt: Date;
  index: number;
};

export type NewFirebaseParticipant = Omit<FirebaseParticipant, 'createdAt'> & {
  createdAt: FieldValue;
};
