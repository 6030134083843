type Config = {
  backendHost: string;
};

let config: Config;

export function initialize(initConfig: Config) {
  config = {
    ...initConfig,
  };
}

export function getBackendHost() {
  return config.backendHost;
}
