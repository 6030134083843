import {
  Category,
  CategoryId,
  categoriesMap,
  defaultFixedCategoriesOrderMap,
  itemCategories,
} from '@reshima/category';
import { AppUser, CategoryItems, Item, List } from './models';

const { Other, Loading } = categoriesMap;

export function isItemChecked({ checked }: Pick<Item, 'checked'>): boolean {
  return !!checked;
}

export function isItemUnchecked({ checked }: Pick<Item, 'checked'>): boolean {
  return !checked;
}

function getCategory({ item }: { item: Item }): Category {
  return (item.categoryId && categoriesMap[item.categoryId]) || Other;
}

export function sortItemsByCheckedUpdatedAt(a: Item, b: Item): number {
  return b.checkedUpdatedAt.getTime() - a.checkedUpdatedAt.getTime();
}

function getLastCheckedUpdatedItem(items: Item[]): Item | undefined {
  return items.sort(sortItemsByCheckedUpdatedAt)[0];
}

function sortCategoriesItemsByCheckedUpdatedAt(
  a: CategoryItems,
  b: CategoryItems,
): number {
  const lastCheckedUpdatedA = getLastCheckedUpdatedItem(a.categoryItems);
  const lastCheckedUpdatedB = getLastCheckedUpdatedItem(b.categoryItems);

  if (!lastCheckedUpdatedA || !lastCheckedUpdatedB) {
    return 0;
  }

  return (
    lastCheckedUpdatedB.checkedUpdatedAt.getTime() -
    lastCheckedUpdatedA.checkedUpdatedAt.getTime()
  );
}

function reduceItemsByCategory({
  items,
}: {
  items: Item[];
}): Record<CategoryId, CategoryItems> {
  return items.reduce(
    (acc, item) => {
      const category = getCategory({ item });

      const existing = acc[category.id];
      const categoryItems = [...(existing?.categoryItems || []), item];

      return {
        ...acc,
        [category.id]: {
          category,
          categoryItems,
        },
      };
    },
    {} as Record<CategoryId, CategoryItems>,
  );
}

export function getListManualSortedItems({
  list,
  items,
}: {
  list: List;
  items: Item[];
}): Item[] {
  const itemsOrderMap: Record<string, number> = Object.fromEntries(
    (list.itemsOrder || []).map((itemId, index) => [itemId, index]),
  );

  return items.sort((a, b) => {
    if (
      Number.isNaN(Number(itemsOrderMap[a.id])) ||
      Number.isNaN(Number(itemsOrderMap[b.id]))
    ) {
      return sortItemsByCheckedUpdatedAt(a, b);
    }

    return itemsOrderMap[a.id] - itemsOrderMap[b.id];
  });
}

export function getSortedItemsByCategory({
  categoriesOrder,
  items,
}: {
  categoriesOrder?: CategoryId[];
  items: Item[];
}): CategoryItems[] {
  return sortCategoriesItemsByOrder({
    categoriesOrder,
    categoriesItems: Object.values(reduceItemsByCategory({ items })),
  });
}

export function getAllCategoriesItems({
  categoriesOrder,
  items,
}: {
  categoriesOrder?: CategoryId[];
  items: Item[];
}): CategoryItems[] {
  const categoriesItems = reduceItemsByCategory({ items });

  const allCategoriesItems = Object.fromEntries(
    itemCategories.map((category) => [
      category.id,
      { category, categoryItems: [] as Item[] },
    ]),
  ) as Record<CategoryId, CategoryItems>;

  const mergedCategoriesItems = {
    ...allCategoriesItems,
    ...categoriesItems,
  };

  return sortCategoriesItemsByOrder({
    categoriesOrder,
    categoriesItems: Object.values(mergedCategoriesItems),
  });
}

export function sortCategoriesByOrder({
  a,
  b,
  categoriesOrderMap,
}: {
  a: CategoryId;
  b: CategoryId;
  categoriesOrderMap: Record<CategoryId, number>;
}): number {
  if (a === Loading.id) {
    return -1;
  }

  if (b === Loading.id) {
    return 1;
  }

  if (a in categoriesOrderMap && b in categoriesOrderMap) {
    return categoriesOrderMap[a] - categoriesOrderMap[b];
  }

  if (a in categoriesOrderMap) {
    return -1;
  }

  if (b in categoriesOrderMap) {
    return 1;
  }

  if (
    a in defaultFixedCategoriesOrderMap &&
    b in defaultFixedCategoriesOrderMap
  ) {
    return (
      defaultFixedCategoriesOrderMap[a] - defaultFixedCategoriesOrderMap[b]
    );
  }

  if (a in defaultFixedCategoriesOrderMap) {
    return -1;
  }

  if (b in defaultFixedCategoriesOrderMap) {
    return 1;
  }

  return a.localeCompare(b);
}

function sortCategoriesItemsByOrder({
  categoriesOrder = [],
  categoriesItems,
}: {
  categoriesOrder?: CategoryId[];
  categoriesItems: CategoryItems[];
}): CategoryItems[] {
  const categoriesOrderMap = Object.fromEntries(
    categoriesOrder.map((category, index) => [category, index]),
  ) as Record<CategoryId, number>;

  return categoriesItems.sort((a, b) =>
    sortCategoriesByOrder({
      a: a.category.id,
      b: b.category.id,
      categoriesOrderMap,
    }),
  );
}

export function getSortedItems({
  categoriesOrder,
  items,
}: {
  categoriesOrder?: CategoryId[];
  items: Item[];
}): Item[] {
  const categoriesItems = getSortedItemsByCategory({
    categoriesOrder,
    items,
  });

  return categoriesItems
    .map(({ categoryItems }) => categoryItems.sort(sortItemsByCheckedUpdatedAt))
    .flat();
}

export function getCategoriesOrder({ items }: { items: Item[] }): CategoryId[] {
  const categorizedItems = items.filter(
    (item) => !isItemChecked(item) && item.categoryId !== Loading.id,
  );

  return Object.values(reduceItemsByCategory({ items: categorizedItems }))
    .sort(sortCategoriesItemsByCheckedUpdatedAt)
    .map(({ category: { id } }) => id);
}

export function isListAdmin({
  list: { admins },
  user: {
    firebaseUser: { uid },
  },
}: {
  list: List;
  user: AppUser;
}): boolean {
  return admins.includes(uid);
}
