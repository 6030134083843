import { CosmosClient } from '@azure/cosmos';
import { DefaultAzureCredential } from '@azure/identity';

const clients: Record<string, CosmosClient> = {};

export function getClient({ endpoint }: { endpoint: string }): CosmosClient {
  if (clients[endpoint]) {
    return clients[endpoint];
  }

  console.log('Initializing Cosmos DB client...', {
    endpoint,
  });

  try {
    const credential = new DefaultAzureCredential();
    const client = new CosmosClient({ endpoint, aadCredentials: credential });

    clients[endpoint] = client;

    console.log('Cosmos DB client initialized', { endpoint });

    return client;
  } catch (error) {
    console.error('Error initializing Cosmos DB client:', error);
    throw error;
  }
}
