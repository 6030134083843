import {
  query,
  where,
  onSnapshot,
  doc,
  serverTimestamp,
  updateDoc,
  Unsubscribe,
  FirestoreError,
  or,
  Query,
  deleteDoc,
  getDocFromCache,
  QueryFieldFilterConstraint,
  getDocsFromServer,
  deleteField,
} from 'firebase/firestore';
import { ActionModifier, trackEvent, trackException } from '@reshima/telemetry';
import { CategoryId } from '@reshima/category';
import {
  AppUser,
  FirebaseList,
  List,
  ListSortBy,
  UpdateAbleList,
} from '../models';
import { getCollection } from '../collections';
import { createList } from '../callable-functions';

const name = 'FirebaseLists';

const getListsCollection = () => getCollection('lists');

function parse({ id, list }: { id: string; list?: FirebaseList }): List {
  const clientCreatedAt = list?.clientCreatedAt?.toDate() || new Date();
  return {
    ...list,
    id,
    admins: list?.admins || [],
    sharedWith: list?.sharedWith || [],
    clientCreatedAt,
    createdAt: list?.createdAt?.toDate() || clientCreatedAt,
    updatedAt: list?.updatedAt?.toDate() || clientCreatedAt,
    sortBy: list?.sortBy || ListSortBy.fixedCategories,
    categoriesOrder: list?.categoriesOrder || [],
  };
}

function ownedQuery({
  firebaseUser: { uid },
}: AppUser): QueryFieldFilterConstraint {
  return where('admins', 'array-contains', uid);
}

function sharedQuery({
  firebaseUser: { uid },
}: AppUser): QueryFieldFilterConstraint {
  return where('sharedWith', 'array-contains', uid);
}

function listsQuery(user: AppUser): Query<FirebaseList> {
  return query(getListsCollection(), or(ownedQuery(user), sharedQuery(user)));
}

async function updateList(list: UpdateAbleList): Promise<void> {
  await updateDoc(doc(getListsCollection(), list.id), {
    ...list,
    updatedAt: serverTimestamp(),
  });
}

export async function deleteList({ id }: List): Promise<void> {
  const action = 'DeleteList';

  const properties = {
    listId: id,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    await deleteDoc(doc(getListsCollection(), id));

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      properties,
      start,
    });
    throw error;
  }
}

export async function getListFromCache({
  listId,
}: {
  listId: string;
}): Promise<List | undefined> {
  const action = 'GetListFromCache';

  const properties = {
    listId,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    const docSnapshot = await getDocFromCache(
      doc(getListsCollection(), listId),
    );

    if (!docSnapshot.exists()) {
      return undefined;
    }

    const list = parse({
      id: docSnapshot.id,
      list: docSnapshot.data(),
    });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });

    return list;
  } catch (error) {
    trackException({
      name,
      action,
      error,
      properties,
      start,
    });
    throw error;
  }
}

export function getListStream({
  listId,
  onListUpdate,
  onError,
}: {
  listId: string;
  onListUpdate: (list?: List) => void;
  onError: (error: FirestoreError) => void;
}): Unsubscribe {
  return onSnapshot(
    doc(getListsCollection(), listId),
    (snapshot) => {
      const list: List = parse({
        id: snapshot.id,
        list: snapshot.data(),
      });

      onListUpdate(list);
    },
    onError,
  );
}

export function getListsStream({
  user,
  onUpdate,
  onError,
}: {
  user: AppUser;
  onUpdate: (lists: List[]) => void;
  onError: (error: FirestoreError) => void;
}): Unsubscribe {
  return onSnapshot(
    listsQuery(user),
    ({ docs }) => {
      const lists = docs.map((doc) =>
        parse({
          id: doc.id,
          list: doc.data(),
        }),
      );
      onUpdate(lists);
    },
    onError,
  );
}

export async function getListsFromServer(user: AppUser): Promise<List[]> {
  const action = 'GetLists';

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
  });

  try {
    const querySnapshot = await getDocsFromServer(listsQuery(user));

    const lists = querySnapshot.docs.map((doc) =>
      parse({
        id: doc.id,
        list: doc.data(),
      }),
    );

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      start,
      properties: {
        listsCount: lists.length,
      },
    });

    return lists;
  } catch (error) {
    trackException({
      name,
      action,
      error,
      start,
    });
    throw error;
  }
}

export async function upsertLists(user: AppUser): Promise<List[]> {
  const userLists = await getListsFromServer(user);
  if (!userLists.length) {
    const list = await createList();
    return [list];
  } else {
    return userLists;
  }
}

export async function updateListCategoriesOrder({
  listId,
  categoriesOrder,
}: {
  listId: string;
  categoriesOrder: CategoryId[];
}): Promise<void> {
  const action = 'UpdateListCategoriesOrder';

  const properties = {
    listId,
    categoriesOrder,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    await updateList({
      id: listId,
      categoriesOrder,
    });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      properties,
      start,
    });
    throw error;
  }
}

export async function updateListFixedCategoriesOrder({
  listId,
  fixedCategoriesOrder,
}: {
  listId: string;
  fixedCategoriesOrder?: CategoryId[];
}): Promise<void> {
  const action = 'UpdateListFixedCategoriesOrder';

  const properties = {
    listId,
    fixedCategoriesOrder,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    await updateList({
      id: listId,
      fixedCategoriesOrder: fixedCategoriesOrder || deleteField(),
    });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      properties,
      start,
    });
    throw error;
  }
}

export async function updateListName({
  listId,
  newName,
}: {
  listId: string;
  newName: string;
}): Promise<void> {
  const action = 'UpdateListName';

  const properties = {
    listId,
    newNameLength: newName?.length,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    await updateList({
      id: listId,
      name: newName,
    });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      start,
      properties,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      start,
      properties,
    });
    throw error;
  }
}

export async function updateListSortBy({
  listId,
  sortBy,
}: {
  listId: string;
  sortBy: ListSortBy;
}): Promise<void> {
  const action = 'UpdateListSortBy';

  const properties = {
    listId,
    sortBy,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    await updateList({
      id: listId,
      sortBy,
    });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      start,
      properties,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      start,
      properties,
    });
    throw error;
  }
}

export async function updateListItemsOrder({
  listId,
  itemsOrder,
}: {
  listId: string;
  itemsOrder: string[];
}): Promise<void> {
  const action = 'UpdateListItemsOrder';

  const properties = {
    listId,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    await updateList({
      id: listId,
      itemsOrder,
    });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      start,
      properties,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      start,
      properties,
    });
    throw error;
  }
}
