import { getBackendHost, backendRoutes } from '@reshima/client-server';
import { UpdateCategory } from './models';

const {
  itemsCategoriesUpdates: { path },
} = backendRoutes;

export async function logUpdateCategory({
  updateCategory,
  token,
}: {
  updateCategory: UpdateCategory;
  token: string;
}): Promise<void> {
  const url = `${getBackendHost()}${path}`;

  const response = await fetch(url, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(updateCategory),
  });

  if (!response.ok) {
    throw new Error(response.statusText);
  }
}
