import { FeedOptions, FeedResponse, SqlQuerySpec } from '@azure/cosmos';
import { getContainer } from './container';

export async function queryItems<T>({
  endpoint,
  databaseId,
  containerId,
  querySpec,
  feedOptions,
}: {
  endpoint: string;
  databaseId: string;
  containerId: string;
  querySpec: SqlQuerySpec;
  feedOptions: FeedOptions;
}): Promise<FeedResponse<T>> {
  try {
    console.log(
      `Querying items from container ${containerId} in database ${databaseId}...`,
    );

    const container = getContainer({ endpoint, databaseId, containerId });
    const queryIterator = container.items.query(querySpec, feedOptions);
    const results = await queryIterator.fetchNext();

    console.log(`Items queried successfully`);

    return results;
  } catch (error) {
    console.error('Error querying items:', error);
    throw error;
  }
}
