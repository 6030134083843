import { ItemDefinition } from '@azure/cosmos';
import { getItem, upsertItem } from '@reshima/azure-cosmos-db';
import { UpdateCategory } from './models';

type ItemsCategoriesUpdateItem = ItemDefinition & {
  count: number;
};

const delimiter = '|';

export async function upsertItemCategoryUpdate({
  endpoint,
  databaseId,
  containerId,
  updateCategory: { categoryId, itemName },
}: {
  endpoint: string;
  databaseId: string;
  containerId: string;
  updateCategory: UpdateCategory;
}) {
  try {
    const itemId = [itemName.replace(delimiter, ''), categoryId].join(
      delimiter,
    );

    const existingItem = await getItem<ItemsCategoriesUpdateItem>({
      endpoint,
      databaseId,
      containerId,
      itemId,
      partitionKeyValue: itemId,
    });

    const count = (existingItem.resource?.count || 0) + 1;

    const item: ItemsCategoriesUpdateItem = {
      id: itemId,
      count,
    };

    await upsertItem({
      endpoint,
      databaseId,
      containerId,
      item,
    });

    console.log('Logged item category update:', item);
  } catch (error) {
    console.error('Failed to log item category update:', error);
  }
}
