import {
  ItemDefinition,
  ItemResponse,
  PartitionKey,
  PatchRequestBody,
} from '@azure/cosmos';
import { getContainer } from './container';

export async function upsertItem<T extends ItemDefinition>({
  endpoint,
  databaseId,
  containerId,
  item,
}: {
  endpoint: string;
  databaseId: string;
  containerId: string;
  item: T;
}): Promise<void> {
  try {
    console.log(
      `Creating item in container ${containerId} in database ${databaseId}...`,
    );

    const container = getContainer({ endpoint, databaseId, containerId });
    await container.items.upsert<T>(item);

    console.log(`Item upserted successfully`);
  } catch (error) {
    console.error('Error creating item:', error);
    throw error;
  }
}

export async function getItem<T extends ItemDefinition>({
  endpoint,
  databaseId,
  containerId,
  itemId,
  partitionKeyValue,
}: {
  endpoint: string;
  databaseId: string;
  containerId: string;
  itemId: string;
  partitionKeyValue: PartitionKey;
}): Promise<ItemResponse<T>> {
  try {
    console.log(
      `Getting item ${itemId} from container ${containerId} in database ${databaseId}...`,
    );

    const container = getContainer({ endpoint, databaseId, containerId });
    const item = await container.item(itemId, partitionKeyValue).read<T>();

    console.log(`Item retrieved successfully`);

    return item;
  } catch (error) {
    console.error('Error getting item:', error);
    throw error;
  }
}

export async function getItems<T extends ItemDefinition>({
  endpoint,
  databaseId,
  containerId,
}: {
  endpoint: string;
  databaseId: string;
  containerId: string;
}): Promise<T[]> {
  try {
    console.log(
      `Getting items from container ${containerId} in database ${databaseId}...`,
    );

    const container = getContainer({ endpoint, databaseId, containerId });
    const items = await container.items.readAll<T>().fetchAll();

    console.log(`Items retrieved successfully`);

    return items.resources;
  } catch (error) {
    console.error('Error getting items:', error);
    throw error;
  }
}

export async function patchItem<T extends ItemDefinition>({
  endpoint,
  databaseId,
  containerId,
  itemId,
  partitionKeyValue,
  patchRequestBody,
}: {
  endpoint: string;
  databaseId: string;
  containerId: string;
  itemId: string;
  partitionKeyValue: PartitionKey;
  patchRequestBody: PatchRequestBody;
}): Promise<ItemResponse<T>> {
  try {
    console.log(
      `patching item ${itemId} in container ${containerId} in database ${databaseId}...`,
    );

    const container = getContainer({ endpoint, databaseId, containerId });
    const updatedItem = await container
      .item(itemId, partitionKeyValue)
      .patch(patchRequestBody);

    console.log(`Item patched successfully`);

    return updatedItem;
  } catch (error) {
    console.error('Error patching item:', error);
    throw error;
  }
}

export async function deleteItem({
  endpoint,
  databaseId,
  containerId,
  itemId,
  partitionKeyValue,
}: {
  endpoint: string;
  databaseId: string;
  containerId: string;
  itemId: string;
  partitionKeyValue: PartitionKey;
}): Promise<void> {
  try {
    console.log(
      `Deleting item ${itemId} from container ${containerId} in database ${databaseId}...`,
    );

    const container = getContainer({ endpoint, databaseId, containerId });
    await container.item(itemId, partitionKeyValue).delete();

    console.log(`Item deleted successfully`);
  } catch (error) {
    console.error('Error deleting item:', error);
    throw error;
  }
}
