import { ActionModifier, trackEvent, trackException } from '@reshima/telemetry';
import { getCategory, suggestCategory } from '@reshima/category-search';
import { logUpdateCategory } from '@reshima/items-categories-updates';
import { CategoryId, categoriesMap } from '@reshima/category';
import { Item, List } from '../models';
import { getCategoriesOrder } from '../utils';
import { getAuthApp } from '../firebase-auth';
import { updateListCategoriesOrder } from './lists';
import { getItemsFromCache, updateItemCategory } from './items';

const name = 'FirebaseItemsCategory';

export async function categoryItem({
  listId,
  itemId,
  itemName,
}: {
  listId: string;
  itemId: string;
  itemName: string;
}): Promise<void> {
  const action = 'CategoryItem';

  const properties = {
    listId,
    itemId,
    name,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    const categoryId = await getCategorizedItem({ itemName });

    await updateCategorizedItem({
      listId,
      itemId,
      categoryId,
    });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      properties,
      start,
    });
    throw error;
  }
}

async function logItemCategoryUpdate({
  item,
  list,
  categoryId,
}: {
  item: Item;
  list: List;
  categoryId: CategoryId;
}): Promise<void> {
  const action = 'LogItemCategoryUpdate';

  const properties = {
    listId: list.id,
    itemId: item.id,
    categoryId,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    const token = await getAuthApp().currentUser?.getIdToken();

    if (!token) {
      throw new Error('No token');
    }

    if (!item.name) {
      throw new Error('No item name');
    }

    await logUpdateCategory({
      updateCategory: {
        itemName: item.name,
        categoryId,
      },
      token,
    });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      properties,
      start,
    });
  }
}

export async function manualUpdateCategorizedItem({
  list,
  item,
  categoryId,
}: {
  list: List;
  item: Item;
  categoryId: CategoryId;
}): Promise<void> {
  logItemCategoryUpdate({ item, list, categoryId });

  await updateCategorizedItem({
    listId: list.id,
    itemId: item.id,
    categoryId,
  });
}

async function updateCategorizedItem({
  listId,
  itemId,
  categoryId,
}: {
  listId: string;
  itemId: string;
  categoryId: CategoryId;
}): Promise<void> {
  updateItemCategory({
    listId,
    itemId,
    categoryId,
  });

  await updateListCategoriesOrderFromCache({ listId });
}

export async function getCategorizedItem({
  itemName,
}: {
  itemName?: string;
}): Promise<CategoryId> {
  if (!itemName) {
    return categoriesMap.Other.id;
  }

  const token = await getAuthApp().currentUser?.getIdToken();

  if (!token) {
    throw new Error('No token');
  }

  const results = await getCategory({ search: itemName, token });

  return results?.bestMatch?.id || categoriesMap.Other.id;
}

export async function suggestCategorizedItem({
  itemName,
}: {
  itemName?: string;
}): Promise<CategoryId> {
  if (!itemName) {
    return categoriesMap.Other.id;
  }

  const token = await getAuthApp().currentUser?.getIdToken();

  if (!token) {
    throw new Error('No token');
  }

  const results = await suggestCategory({ search: itemName, token });

  return results?.bestMatch?.id || categoriesMap.Other.id;
}

export async function updateListCategoriesOrderFromCache({
  listId,
}: {
  listId: string;
}) {
  const action = 'UpdateListCategoriesOrderFromCache';

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties: { listId },
  });

  try {
    const items = await getItemsFromCache({ listId });

    const categoriesOrder = getCategoriesOrder({ items });

    await updateListCategoriesOrder({ listId, categoriesOrder });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties: { listId },
      start,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      properties: { listId },
      start,
    });
    throw error;
  }
}
