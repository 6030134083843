import {
  CollectionReference,
  FirestoreError,
  Timestamp,
  Unsubscribe,
  collection,
  deleteDoc,
  deleteField,
  doc,
  getDocFromCache,
  getDocs,
  getDocsFromCache,
  onSnapshot,
  serverTimestamp,
  setDoc,
  updateDoc,
  writeBatch,
} from 'firebase/firestore';
import { Units, isUnit, randomHash } from '@reshima/shared';
import { categoriesMap, CategoryId } from '@reshima/category';
import { FirebaseItem, Item, List, UpdateAbleItem } from '../models';
import { getFirestoreApp } from '../firebase-firestore';
import {
  Action,
  ActionModifier,
  trackEvent,
  trackException,
} from '@reshima/telemetry';
import {
  categoryItem,
  getCategorizedItem,
  updateListCategoriesOrderFromCache,
} from './items-category';

const name = 'FirebaseItems';

export const getListItemsCollection = ({ listId }: { listId: string }) =>
  collection(
    getFirestoreApp(),
    `lists/${listId}/items`,
  ) as CollectionReference<FirebaseItem>;

function parse({ id, item }: { id: string; item?: FirebaseItem }): Item {
  const clientCreatedAt = item?.clientCreatedAt?.toDate() || new Date();
  const clientUpdatedAt = item?.clientUpdatedAt?.toDate() || clientCreatedAt;
  const checkedUpdatedAt = item?.checkedUpdatedAt?.toDate() || clientCreatedAt;
  const createdAt = item?.createdAt?.toDate() || clientCreatedAt;
  const updatedAt = item?.updatedAt?.toDate() || clientUpdatedAt;
  const categoryId = item?.categoryId || categoriesMap.Other.id;
  const count =
    item?.count && !isNaN(item.count) && item.count > 0 ? item.count : 0;
  const unit = item?.unit && isUnit(item.unit) ? item.unit : Units.pcs;
  const name = item?.name || '';

  return {
    ...item,
    id,
    name,
    clientCreatedAt,
    clientUpdatedAt,
    checkedUpdatedAt,
    createdAt,
    updatedAt,
    categoryId,
    count,
    unit,
  };
}

async function getItemFromCache({
  listId,
  itemId,
}: {
  listId: string;
  itemId: string;
}): Promise<Item> {
  const snapshot = await getDocFromCache(
    doc(getListItemsCollection({ listId }), itemId),
  );

  if (!snapshot.exists()) {
    throw new Error('Item not found');
  }

  return parse({
    id: snapshot.id,
    item: snapshot.data(),
  });
}

export async function getItemsFromCache({
  listId,
}: {
  listId: string;
}): Promise<Item[]> {
  const action = 'GetItemsFromCache';

  const properties = { listId };

  trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    const snapshot = await getDocsFromCache(getListItemsCollection({ listId }));

    const items = snapshot.docs.map((doc) =>
      parse({
        id: doc.id,
        item: doc.data(),
      }),
    );

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties: {
        ...properties,
        itemsCount: items.length,
      },
    });

    return items;
  } catch (error) {
    trackException({
      name,
      action,
      error,
      properties,
    });
    throw error;
  }
}

export async function getItems({
  listId,
}: {
  listId: string;
}): Promise<Item[]> {
  const items = await getDocs(getListItemsCollection({ listId }));

  return items.docs.map((doc) =>
    parse({
      id: doc.id,
      item: doc.data(),
    }),
  );
}

export function getItemsStream({
  listId,
  onUpdate,
  onError,
}: {
  listId: string;
  onUpdate: (items: Item[]) => void;
  onError: (error: FirestoreError) => void;
}): Unsubscribe {
  return onSnapshot(
    getListItemsCollection({ listId }),
    (snapshot) => {
      const items = snapshot.docs.map((doc) =>
        parse({
          id: doc.id,
          item: doc.data(),
        }),
      );

      onUpdate(items);
    },
    onError,
  );
}

export async function restoreItem({
  listId,
  item,
}: {
  listId: string;
  item: Item;
}): Promise<void> {
  const action = 'RestoreItem';

  const properties = { listId, itemId: item.id };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    const itemsCollection = getListItemsCollection({ listId });

    await setDoc(doc(itemsCollection, item.id), item as any); // TODO: fix type

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      properties,
      start,
    });
    throw error;
  }
}

export async function addItem({
  listId,
  item: { name: itemName, count, unit, categoryId },
}: {
  listId: string;
  item: {
    name: string;
    count: number;
    unit: Units;
    categoryId?: CategoryId;
  };
}): Promise<Item> {
  const action = 'AddItem';

  const properties = {
    listId,
    name,
    count,
    unit,
    categoryId,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    const itemsCollection = getListItemsCollection({ listId });

    const itemId = randomHash(7);

    setDoc(doc(itemsCollection, itemId), {
      name: itemName,
      count,
      unit,
      categoryId: categoryId || categoriesMap.Loading.id,
      clientCreatedAt: Timestamp.now(),
      clientUpdatedAt: Timestamp.now(),
      checkedUpdatedAt: Timestamp.now(),
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    });

    const addedItem = await getItemFromCache({ listId, itemId });

    if (categoryId) {
      getCategorizedItem({ itemName });
    } else {
      categoryItem({
        listId,
        itemId,
        itemName,
      });
    }

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });

    return addedItem;
  } catch (error) {
    trackException({
      name,
      action,
      error,
      properties,
      start,
    });
    throw error;
  }
}

function getUpdateItem(item: UpdateAbleItem): UpdateAbleItem {
  return {
    ...item,
    clientUpdatedAt: Timestamp.now(),
    updatedAt: serverTimestamp(),
  };
}

export async function updateItem({
  listId,
  item,
}: {
  listId: string;
  item: UpdateAbleItem;
}): Promise<void> {
  await updateDoc(
    doc(getListItemsCollection({ listId }), item.id),
    getUpdateItem(item),
  );
}

export async function updateItemCategory({
  listId,
  itemId,
  categoryId,
}: {
  listId: string;
  itemId: string;
  categoryId: CategoryId;
}): Promise<void> {
  const action = 'UpdateItemCategory';

  const properties = { listId, itemId, categoryId };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    await updateItem({
      listId,
      item: {
        id: itemId,
        categoryId,
      },
    });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      properties,
      start,
    });
    throw error;
  }
}

function getUpdateCheckedItem({
  id,
  checked,
}: {
  id: string;
  checked: boolean;
}): UpdateAbleItem {
  return {
    ...getUpdateItem({ id }),
    ...{
      checked: checked || deleteField(),
      checkedUpdatedAt: Timestamp.now(),
    },
  };
}

export async function updateItemChecked({
  listId,
  itemId,
  checked,
}: {
  listId: string;
  itemId: string;
  checked: boolean;
}): Promise<void> {
  const action = 'UpdateItemChecked';

  const properties = { listId, itemId, checked };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    const item = getUpdateCheckedItem({ id: itemId, checked });

    await Promise.all([
      updateItem({
        listId,
        item,
      }),
      ...(checked ? [] : [updateListCategoriesOrderFromCache({ listId })]),
    ]);

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      properties,
      start,
    });
    throw error;
  }
}

export async function updateItemCount({
  listId,
  itemId,
  count,
}: {
  listId: string;
  itemId: string;
  count: number;
}): Promise<void> {
  const action = 'UpdateItemCount';

  const properties = { listId, itemId, count };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    await updateItem({
      listId,
      item: {
        id: itemId,
        count,
      },
    });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      properties,
      start,
    });
    throw error;
  }
}

export async function updateItemUnit({
  listId,
  itemId,
  unit,
}: {
  listId: string;
  itemId: string;
  unit: Units;
}): Promise<void> {
  const action = 'UpdateItemUnit';

  const properties = { listId, itemId, unit };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    await updateItem({
      listId,
      item: {
        id: itemId,
        unit,
      },
    });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      properties,
      start,
    });
    throw error;
  }
}

export async function updateItemName({
  list,
  itemId,
  itemName,
}: {
  list: List;
  itemId: string;
  itemName: string;
}): Promise<void> {
  const action = 'UpdateItemName';

  const properties = {
    listId: list.id,
    itemId,
    itemName,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    const updatedItem = {
      id: itemId,
      name: itemName,
    };

    await updateItem({
      listId: list.id,
      item: updatedItem,
    });

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      properties,
      start,
    });
    throw error;
  }
}

export async function deleteItem({
  listId,
  item,
}: {
  listId: string;
  item: Item;
}): Promise<void> {
  const action = 'DeleteItem';

  const properties = { listId, itemId: item.id };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    await deleteDoc(doc(getListItemsCollection({ listId }), item.id));

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      properties,
      start,
    });
    throw error;
  }
}

export async function checkItems({
  list,
  items,
  checked,
}: {
  list: List;
  items: Item[];
  checked: boolean;
}): Promise<void> {
  const action = 'CheckItems';

  const properties = {
    listId: list.id,
    itemsCount: items.length,
    checked,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    const batch = writeBatch(getFirestoreApp());

    items.forEach(({ id }) => {
      const item = getUpdateCheckedItem({ id, checked });
      batch.update(
        doc(getListItemsCollection({ listId: list.id }), item.id),
        item,
      );
    });

    await batch.commit();

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      properties,
      start,
    });
    throw error;
  }
}

export async function deleteItems({
  list,
  items,
}: {
  list: List;
  items: Item[];
}): Promise<void> {
  const action = 'DeleteItems';

  const properties = {
    listId: list.id,
    itemsCount: items.length,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    const batch = writeBatch(getFirestoreApp());

    items.forEach(({ id }) => {
      batch.delete(doc(getListItemsCollection({ listId: list.id }), id));
    });

    await batch.commit();

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      properties,
      start,
    });
    throw error;
  }
}

export async function restoreItems({
  list,
  items,
}: {
  list: List;
  items: Item[];
}): Promise<void> {
  const action = 'RestoreItems';

  const properties = {
    listId: list.id,
    itemsCount: items.length,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    const batch = writeBatch(getFirestoreApp());

    items.forEach((item) => {
      batch.set(
        doc(getListItemsCollection({ listId: list.id }), item.id),
        item as any, // TODO: fix type
      );
    });

    await batch.commit();

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      properties,
      start,
    });
    throw error;
  }
}

type CategorizingItem = {
  id: string;
  categoryId: CategoryId;
};

export async function categorizeItems({
  list,
  items,
}: {
  list: List;
  items: CategorizingItem[];
}): Promise<void> {
  const action = Action.Categorize;

  const properties = {
    listId: list.id,
    itemsCount: items.length,
  };

  const start = trackEvent({
    name,
    action,
    actionModifier: ActionModifier.Start,
    properties,
  });

  try {
    const batch = writeBatch(getFirestoreApp());

    items.forEach(({ id, categoryId }) => {
      batch.update(doc(getListItemsCollection({ listId: list.id }), id), {
        categoryId,
      });
    });

    await batch.commit();

    trackEvent({
      name,
      action,
      actionModifier: ActionModifier.End,
      properties,
      start,
    });
  } catch (error) {
    trackException({
      name,
      action,
      error,
      properties,
      start,
    });
    throw error;
  }
}
